
import { addDiaryMeal } from '@/api/diary';
import { Form } from 'element-ui';
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component({
})

export default class extends Vue {
  mounted() {
    if (this.$route.query.diaryDate && this.$route.query.type) {
      this.form.diaryDate = this.$route.query.diaryDate;
      this.form.type = this.$route.query.type;
    } else {
      this.$message.warning('잘못된 접근입니다.');
      this.$router.push({ name: 'Diary' });
    }
  }

  private form: any = {
    menuName: '',
    amount: 0,
    amountUnit: 'g',
    calorie: 0,
    carbohydrate: 0, // 탄수화물(g)
    protein: 0, // 단백질(g)
    fat: 0, // 지방(g)
    sodium: 0, // 나트륨(mg)
    sugar: 0, // 당(g)
    saturatedFattyAcids: 0, // 포화지방(g)
    vitaminC: 0, // 비타민c(mg)
    transFat: 0, // 트랜스지방(mg)
    cholesterol: 0, // 콜레스테롤(mg)
    calcium: 0, // 칼슘(mg)
    iron: 0, // 철(mg)
    potassium: 0, // 컬륨(mg)
    dietaryFiber: 0, // 식이섬유(g)
    type: 0,
    diaryDate: '',
  }

  private rules = {
    menuName: [
      { required: true, message: '메뉴명을 입력해주세요.', trigger: ['blur', 'change'] },
    ],
    amount: [
      { required: true, message: '섭취량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    calorie: [
      { required: true, message: '칼로리를 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    carbohydrate: [
      { required: true, message: '탄수화물 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    protein: [
      { required: true, message: '단백질 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    fat: [
      { required: true, message: '지방 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    sodium: [
      { required: true, message: '나트륨 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    sugar: [
      { required: true, message: '당 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    saturatedFattyAcids: [
      { required: true, message: '포화지방 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    vitaminC: [
      { required: true, message: '비타민C 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    transFat: [
      { required: true, message: '트랜스 지방 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    cholesterol: [
      { required: true, message: '콜레스테롤 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    calcium: [
      { required: true, message: '칼슘 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    iron: [
      { required: true, message: '철 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    potassium: [
      { required: true, message: '칼륨 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
    dietaryFiber: [
      { required: true, message: '식이섬유 함량을 입력해주세요.', trigger: ['blur', 'change'] },
      { pattern: /^[0-9]+(.[0-9]+)?$/, message: '숫자만 입력 가능합니다.', trigger: ['blur', 'change'] },
    ],
  }

  private unitList = ['g', 'ml'];

  private handleAddDiaryMeal() {
    (this.$refs.form as Form).validate((valid: boolean) => {
      if (valid) {
        addDiaryMeal(this.form).then(() => {
          this.$message.success('다이어리에 추가되었습니다.');
          this.$router.push({ name: 'Diary', query: { ...this.$route.query } });
        });
      }
    });
  }
}
